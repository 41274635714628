import React from "react";
import "./GuestLayout.css";

export const GuestLayout = ({children}) => {
  return (
    <>
      <div className="container guest-container">
        {children}
      </div>
    </>
  )
}