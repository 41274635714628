import React, {useState} from "react";
import "../Modal.css";
import {client} from "../../../services/client";
import {notification} from "../../Notification";

export const ModalForgotPassword = ({setIsOpen}) => {
  const [email, setEmail] = useState("");

  const handleRequestResetPassword = async (e) => {
    e.preventDefault();
    await client.requestResetPassword({
      email: e.target.email.value
    })
      .then(() => {
        notification.success("Recovery password link has been sent to your email");
        setIsOpen(false);
      })
      .catch((error) => notification.warning(error.message));
  }

  return (
    <>
      <div className="modal-bg" onClick={() => setIsOpen(false)}/>
      <div className="modal">
        <div className="modal-close-button" onClick={() => setIsOpen(false)}>+</div>
        <div>Enter your email</div>
        <form onSubmit={handleRequestResetPassword}>
          <input
            type="text"
            name="email"
            placeholder="Email"
            className="modal-input"
          />

          <div className="modal-button-container">
            <button type="submit">Request</button>
          </div>
        </form>

      </div>
    </>
  )
}