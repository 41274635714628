import React from "react";
import "./Landing.css";
import {LoginForm} from "../../components/LoginForm/LoginForm";

export const Landing = () => {
  return (
    <div className="content landing-content">
      <div className="logo"/>
      <h3 className="landing-description">The Next Social Media Revolution</h3>

      <LoginForm/>

      <div className="invitation-link">Ask for invitation to BETA testing here.</div>

    </div>
  )
}