import React, {useEffect, useState} from "react";
import "./Account.css";
import {useSelector} from "react-redux";
import {client} from "../../../services/client";
import {notification} from "../../../components/Notification";

export const Account = () => {
  const user = useSelector(store => store.base.user);
  const [userData, setUserData] = useState();

  const changeUserField = (e) => {
    switch (e.target.name) {
      case "firstName" :
        setUserData({...userData, firstName: e.target.value});
        break;
      case "lastName" :
        setUserData({...userData, lastName: e.target.value});
        break;
      case "email" :
        setUserData({...userData, email: e.target.value});
        break;
      case "dateOfBirth" :
        setUserData({...userData, dateOfBirth: e.target.value});
        break;
      case "phone" :
        setUserData({...userData, phone: e.target.value});
        break;
      case "username" :
        if (!user.usernameChanged) {
          setUserData({...userData, username: e.target.value});
        }
        break;
      case "address" :
        setUserData({...userData, address: e.target.value});
        break;
      case "postal" :
        setUserData({...userData, postal: e.target.value});
        break;
      case "city" :
        setUserData({...userData, city: e.target.value});
        break;
      case "region" :
        setUserData({...userData, region: e.target.value});
        break;
    }
  }

  useEffect(() => {
    if (user) {
      setUserData({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        dateOfBirth: user.dateOfBirth,
        phone: user.phone,
        username: user.username,
        address: user.address,
        postal: user.postal,
        city: user.city,
        region: user.region,
      })
    }
  }, [user]);

  const handleUpdate = async () => {
    await client.updateUserProfile(userData)
      .then(() => notification.success("Profile updated"))
      .catch((error) => notification.warning(error.message));
  }

  return (
    <div>
      <form className="account">
        <div className="account-box">
          <div>
            <div className="account-box-label">First name:</div>
            <input
              type="text"
              name="firstName"
              value={userData?.firstName}
              onChange={changeUserField}
            />
          </div>
          <div>
            <div className="account-box-label">Last name:</div>
            <input
              type="text"
              name="lastName"
              value={userData?.lastName}
              onChange={changeUserField}
            />
          </div>
          <div>
            <div className="account-box-label">Date of birth (YYYY-MM-DD):</div>
            <input
              type="text"
              name="dateOfBirth"
              value={userData?.dateOfBirth}
              onChange={changeUserField}
            />
          </div>
          <div>
            <div className="account-box-label">Country:</div>
            <input
              type="text"
              value={user?.countryName}
              disabled
            />
          </div>
          <div>
            <div className="account-box-label">Email:</div>
            <input
              type="email"
              name="email"
              value={userData?.email}
              disabled
            />
          </div>
          <div>
            <div className="account-box-label">Mobile phone*:</div>
            <input
              type="tel"
              name="phone"
              value={userData?.phone}
              onChange={changeUserField}
            />
          </div>
        </div>
        <div className="account-box">
          <div>
            <div className="account-box-label">Member since:</div>
            <input
              type="text"
              value={user?.createdAt}
              disabled
            />
          </div>
          <div>
            <div className="account-box-label">Username (you can change it only once)*:</div>
            <input
              type="text"
              name="username"
              value={userData?.username}
              disabled={userData?.usernameChanged}
              onChange={changeUserField}
            />
          </div>
          <div>
            <div className="account-box-label">Street address*:</div>
            <input
              type="text"
              name="address"
              value={userData?.address}
              onChange={changeUserField}
            />
          </div>
          <div>
            <div className="account-box-label">Postal code*:</div>
            <input
              type="text"
              name="postal"
              value={userData?.postal}
              onChange={changeUserField}
            />
          </div>
          <div>
            <div className="account-box-label">City*:</div>
            <input
              type="text"
              name="city"
              value={userData?.city}
              onChange={changeUserField}
            />
          </div>
          <div>
            <div className="account-box-label">Region*:</div>
            <input
              type="text"
              name="region"
              value={userData?.region}
              onChange={changeUserField}
            />
          </div>
        </div>
      </form>

      <div className="account-form-label">*You can only update this information</div>

      <div className="account-button-container">
        <button onClick={handleUpdate}>Save</button>
      </div>
    </div>
  )
}