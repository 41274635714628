export const API_URLS = {
  OAUTH: {
    TOKEN: 'oauth/token',
  },
  USERS: {
    ROOT: 'users',
    CURRENT: 'users/current',
    UPLOAD_KYC: 'users/upload/kyc',
    REQUEST_RESET_PASSWORD: 'users/requestResetPassword',
    CONFIRM_RESET_PASSWORD: 'users/confirmResetPassword',
  },
  WALLETS: {
    GET_USER_WALLET: 'wallets/user'
  },
  SSE: {
    STREAM: 'sse/public/stream'
  },
  STAT: {
    PROFITING: `stat/profiting`
  },
  CURRENCIES: {
    RATES_HISTORY_BY_WEEK: 'currencies/rates/history/getRatesByWeek'
  },
};
