import React from "react";
import "./Header.css";
import {useHistory} from "react-router-dom";
import {ROUTE} from "../../constants/routes";
import {useDispatch} from "react-redux";
import {logout} from "../../store/actions/actions";
import {isMobile} from "../../utils/checkDevice";

export const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const menuItems = [
    {id: 1, title: "Account", url: ROUTE.ACCOUNT},
    {id: 2, title: "KYC", url: ROUTE.KYC},
    {id: 3, title: "Holdings", url: ROUTE.HOLDINGS},
    {id: 4, title: "Exchange", url: ROUTE.PROJECT},
    {id: 5, title: "Project", url: ROUTE.PROJECT},
  ];

  return (
    <div className="header">
      <div className="header-container">
        {!isMobile() && <div className="logo-small"/>}

        <div className="main-menu">
          {menuItems.map((item) => {
            return <div key={item.id} onClick={() => {
              if (item.id === 4) {
                window.open("https://dosx.io", "_blank");
              } else {
                history.push(item.url);
              }
            }
            }>{item.title}</div>
          })}
        </div>

        <div className="logout" onClick={() => dispatch(logout())}/>
      </div>
    </div>
  )
}