import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {notification} from "../../components/Notification";
import {ROUTE} from "../../constants/routes";
import {client} from "../../services/client";

export const ConfirmPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState();

  useEffect(() => {
    const code = new URLSearchParams(history.location.search).get('confirmationCode');
    if (!code) {
      notification.warning("Wrong code");
      history.push(ROUTE.ROOT);
    }
    setConfirmationCode(code);
  });

  const handleConfirm = async (e) => {
    e.preventDefault();
    try {
      await client.confirmResetPassword({
        password: e.target.password.value,
        confirmationCode: confirmationCode
      });
      notification.success("Password changed. You can login now.");
      history.push(ROUTE.ROOT);
    } catch (error) {
      notification.warning(error.message);
    }
  }

  return(
    <div className="reset-password">
        <form onSubmit={handleConfirm}>
          <div className="form-item">
            <input
              id="password"
              type="password"
              required
              autoComplete="off"
              placeholder="New password"
            />
          </div>
          <div className="form-item form-button-container">
            {loading ? <span className="loader"/> : <button type="submit" className="button-blue">Submit</button>}
          </div>
        </form>
    </div>
  )

}