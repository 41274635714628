import React, {useEffect, useState} from "react";
import "./LoginForm.css";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logout, setAuth, setUser} from "../../store/actions/actions";
import {jwt} from "../../services/jwt";
import {client} from "../../services/client";
import {ROUTE} from "../../constants/routes";
import {notification} from "../Notification";
import {ModalForgotPassword} from "../Modal/ModalForgotPassword/ModalForgotPassword";

export const LoginForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isForgotPasswordDialogOpen, setIsForgotPasswordDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(logout());
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const email = e.target.email.value;
    const password = e.target.password.value;
    try {
      const response = await client.auth2fa({email, password});
      if (response.access_token !== '') {
        jwt.setJwtData(response.access_token, response.refresh_token);
        const user = await client.getCurrentUser();
        dispatch(setUser(user));
        dispatch(setAuth(true));
        history.push(ROUTE.ACCOUNT);
        setLoading(false);
      }
    } catch (error) {
      notification.warning(error.message);
      setLoading(false);
    }
  }

  return (
    <div className="login-form">
      <h3 className="login-title">Login</h3>

      <form className="form" onSubmit={handleLogin}>
        <div className="form-item">
          <input
            id="email"
            type="text"
            required
            autoComplete="off"
            placeholder="Username or email"
          />
        </div>
        <div className="form-item">
          <input
            id="password"
            type="password"
            required
            autoComplete="off"
            placeholder="Password"
          />
        </div>
        <div className="forgot-password-link" onClick={() => setIsForgotPasswordDialogOpen(true)}>Forgot password?</div>

        <div className="login-button-container">
          <button type="submit">Login</button>
        </div>
      </form>

      {isForgotPasswordDialogOpen && <ModalForgotPassword setIsOpen={setIsForgotPasswordDialogOpen}/>}

    </div>
  )
}