import React from 'react';
import {Route, Switch} from "react-router";
import {Redirect} from "react-router-dom";
import {MainLayout} from "./components/Layouts/MainLayout/MainLayout";
import {GuestLayout} from "./components/Layouts/GuestLayout/GuestLayout";
import {ROUTE} from "./constants/routes";
import {Landing} from "./pages/landing/Landing";
import {Account} from "./pages/dashboard/Account/Account";
import {Project} from "./pages/dashboard/Project/Project";
import {Holdings} from "./pages/dashboard/Holdings/Holdings";
import {KYC} from "./pages/dashboard/Kyc/KYC";
import {ConfirmPassword} from "./pages/landing/ConfirmPassword";


export const useRoutes = (isAuth) => {
  if (isAuth) {
    return (
      <MainLayout>
        <Switch>
          <Route path={ROUTE.ACCOUNT} exact={true}>
            <Account/>
          </Route>

          <Route path={ROUTE.KYC} exact={true}>
            <KYC/>
          </Route>

          <Route path={ROUTE.HOLDINGS} exact={true}>
            <Holdings/>
          </Route>

          <Route path={ROUTE.PROJECT} exact={true}>
            <Project/>
          </Route>

          <Route path={ROUTE.ROOT}>
            <Redirect to={ROUTE.ACCOUNT}/>
          </Route>
        </Switch>
      </MainLayout>
    )
  } else {
    return (
      <GuestLayout>
        <Switch>
          <Route path={ROUTE.ROOT} exact={true}>
            <Landing/>
          </Route>

          <Route path={ROUTE.PASSWORD_RESET} exact={true}>
            <ConfirmPassword/>
          </Route>

          <Route path={ROUTE.ROOT}>
            <Redirect to={ROUTE.ROOT}/>
          </Route>
        </Switch>
      </GuestLayout>
    )
  }
}