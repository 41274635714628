import React, {useCallback, useEffect, useState} from "react";
import "./Holdings.css";
import {isMobile} from "../../../utils/checkDevice";
import {Line} from "react-chartjs-2";
import {defaults} from 'react-chartjs-2';
import {notification} from "../../../components/Notification";
import {client} from "../../../services/client";
import {toEur} from "../../../utils/tools";

export const Holdings = () => {
  const [profiting, setProfiting] = useState();
  const [rates, setRates] = useState();
  const [dates, setDates] = useState();
  const [wallet, setWallet] = useState();

  useEffect(() => {
    client.getUserWallet().then(setWallet).catch((error) => notification.warning(error.message));
  }, [])

  const dataSet = {
    labels: dates && dates,
    datasets: [
      {
        label: 'EUR',
        fill: "start",
        lineTension: 0,
        // backgroundColor: (context) => {
        //   const ctx = context.chart.ctx;
        //   const gradient = ctx.createLinearGradient(0, 0, 0, 280);
        //   gradient.addColorStop(0, "rgba(0, 122, 255, 0.33)");
        //   gradient.addColorStop(1, "rgba(255, 255, 255, 0.7)");
        //   return gradient;
        // },
        borderColor: '#FFF',
        borderWidth: 1,
        data: rates && rates
      }
    ]
  }

  useEffect(async () => {
    const _dates = [];
    const _rates = [];
    await client.getRatesHistoryByWeek({
      currencyCode: "PPC"
    })
      .then(response => {
        Object.entries(response).forEach(([k, v]) => {
          _dates[k] = v.date;
          _rates[k] = v.eurRate;
        });
      });
    setDates(_dates);
    setRates(_rates);
  }, []);

  const getProfiting = useCallback(async () => {
    try {
      const response = await client.getProfiting();
      setProfiting(response);
    } catch (e) {
      notification.warning(e.message);
    }
  });

  useEffect(() => {
    getProfiting();
  }, []);

  return (
    <div className="holdings">
      <div className="holdings-box-container">
        <div className="holdings-box">
          <table>
            <tbody>
            <tr>
              <td>PPC price</td>
              <td>{profiting && toEur(profiting.ppcPrice)}</td>
            </tr>
            <tr>
              <td>Balance</td>
              <td>{profiting && toEur(profiting.ppcBalance, false)}</td>
            </tr>
            <tr>
              <td>Value</td>
              <td>{profiting && toEur(profiting.totalPpcValue)}</td>
            </tr>
            <tr>
              <td>30-day change</td>
              <td>{profiting && (profiting.ppcChangeMonth < 0 ? toEur(profiting.ppcChangeMonth, false) : "+" + toEur(profiting.ppcChangeMonth, false))}%</td>
            </tr>
            <tr>
              <td>Total change</td>
              <td>{profiting && (profiting.ppcChangeTotal < 0 ? toEur(profiting.ppcChangeTotal, false) : "+" + toEur(profiting.ppcChangeTotal, false))}%</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="holdings-box">
          <table>
            <tbody>
            <tr>
              <td>VCT price</td>
              <td>{profiting && toEur(profiting.vctListingPrice)}</td>
            </tr>
            <tr>
              <td>Balance</td>
              <td>{profiting && toEur(profiting.vctBalance, false)}</td>
            </tr>
            <tr>
              <td>Value</td>
              <td>{profiting && toEur(profiting.totalVctValue)}</td>
            </tr>
            <tr>
              <td>Cash wallet</td>
              <td>{wallet && toEur(wallet.cashBalance)}</td>
            </tr>
            <tr>
              <td>Trading wallet</td>
              <td>{wallet && toEur(wallet.tradingBalance)}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="holdings-chart">
        <div className="holdings-chart-title">PPC Growth chart</div>
        {dataSet && <Line
          data={dataSet}
          options={{
            title: {
              display: true,
              text: 'PPC',
              fontSize: 20
            },
            plugins: {
              legend: {
                display: false
              }
            }
          }}
        />}
      </div>
    </div>
  )
}