import {handleActions} from 'redux-actions'
import {LOG_OUT, SET_AUTH, SET_USER, SET_CATEGORIES, IS_MOBILE_MENU_OPEN, SET_UPDATED_LOTS} from '../actions/actionTypes'

const initialState = {
  isAuth: false,
  user: null,
  categories: null,
  isMobileMenuOpen: false,
  updatedLots: null,
}

export const baseReducer = handleActions(
  {
    SET_AUTH: (state, {payload}) => {
      return {
        ...state,
        isAuth: payload,
      }
    },
    LOG_OUT: () => initialState,
    SET_USER: (state, {payload}) => ({
      ...state,
      user: payload,
    }),
    SET_CATEGORIES: (state, {payload}) => ({
      ...state,
      categories: payload,
    }),
    IS_MOBILE_MENU_OPEN: (state, {payload}) => ({
      ...state,
      isMobileMenuOpen: payload,
    }),
    SET_UPDATED_LOTS: (state, {payload}) => ({
      ...state,
      updatedLots: payload,
    }),
  },
  initialState,
)
