import React, {useCallback, useEffect} from "react";
import {Header} from "../../Header/Header";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../../store/actions/actions";
import {client} from "../../../services/client";
import "./MainLayout.css";

export const MainLayout = ({children}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(store => store.base.user);

  const fetchCurrentUser = useCallback(async () => {
    const isAuth = !!localStorage.accessToken && !!localStorage.refreshToken;
    if (user === null && isAuth) {
      const user = await client.getCurrentUser();
      dispatch(setUser(user));
    }
  }, [user, history]);

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  return (
    <>
      <div className="container dashboard-container">
        <Header/>
        <div className="dashboard-wrapper">
          {children}
        </div>
      </div>
    </>
  )
}