import React, {useState} from "react";
import "./KYC.css"
import {useDispatch, useSelector} from "react-redux";
import {client} from "../../../services/client";
import {useHistory} from "react-router-dom";
import {setUser} from "../../../store/actions/actions";
import {notification} from "../../../components/Notification";

export const KYC = () => {
  const user = useSelector(store => store.base.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [proofIdFile, setProofIdFile] = useState();
  const [proofResidenceFile, setProofResidenceFile] = useState();


  const handleIDUpload = (e) => {
    setProofIdFile(e.target.files[0]);
  }

  const handleResidenceUpload = (e) => {
    setProofResidenceFile(e.target.files[0]);
  }

  const handleSubmit = async () => {
    const formData = new FormData();
    proofIdFile && formData.append('proofIdFile', proofIdFile);
    formData.append('proofResidenceFile', proofResidenceFile);

    await client.uploadKyc(formData)
      .then((r) => dispatch(setUser(r)))
      .catch((error) => notification.warning(error.message));
  }

  const renderContent = () => {
    if (user) {
      if (user.step !== "FILL_PROFILE") {
        return <>
          <table className="kyc-table">
            <tbody>
            <tr>
              <td>Status KYC:</td>
              <td>{user && user.kycStatus}</td>
            </tr>
            <tr>
              <td>
                <div>Upload proof of ID:</div>
                <div className="kyc-hint">Acceptable IDs are; Passport, Driving licens or National ID card.</div>
              </td>
              <td>
                <label htmlFor="proof-id-file">
                  <div className="kyc-upload-button">
                    <div className="upload-icon"/>
                    Upload
                    <input
                      hidden
                      accept="image/*"
                      id="proof-id-file"
                      type="file"
                      onChange={handleIDUpload}
                    />
                  </div>
                  <span className="kyc-filename">{proofIdFile && proofIdFile.name}</span>
                </label>
              </td>
            </tr>
            <tr>
              <td>
                <div>Upload proof of residence</div>
                <div className="kyc-hint">Acceptable proof of address are; Utility bill, Income statement, Tax
                  document.
                </div>
              </td>
              <td>
                <label htmlFor="proof-residence-file">
                  <div className="kyc-upload-button">
                    <div className="upload-icon"/>
                    Upload
                    <input
                      hidden
                      accept="image/*"
                      id="proof-residence-file"
                      type="file"
                      onChange={handleResidenceUpload}
                    />
                  </div>
                  <span className="kyc-filename">{proofResidenceFile && proofResidenceFile.name}</span>
                </label>
              </td>
            </tr>
            <tr>
              <td className="kyc-hint">(format allowed; PDF, JPG and PNG files. Max size 2MB)</td>
              <td/>
            </tr>
            </tbody>
          </table>
          <div className="kyc-button-container">
            <button onClick={handleSubmit}>Save</button>
          </div>
        </>
      } else {
        return <div>You must fill account data first before upload KYC files.</div>
      }
    }
  }

  return (
    <div className="kyc">
      <div className="kyc-title">KYC is required to complete the SAFTE agreement to convert PPC and VCT to shares.</div>

      {renderContent()}

    </div>
  )
}