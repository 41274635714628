import React from "react";
import "./Project.css";
import {isMobile} from "../../../utils/checkDevice";

export const Project = () => {
  return (
    <div className="project">
      <div className="project-title">The project of a new Social Media platform 3.0 where you are in control of your
        content and have the freedom of speech.
      </div>
      <div className="project-video">
        <iframe
          width={isMobile() ? "100%" : "710"}
          height={isMobile() ? "200" : "400"}
          src={`https://www.youtube.com/embed/HknMcKA6L68`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  )
}