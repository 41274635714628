import { jwt } from './jwt';
import { api } from './api';

import { API_URLS } from './apiUrls';

const BASIC_CODE = process.env.REACT_APP_BASIC_CODE;

export class Client {

  //Users
  auth2fa = ({ email, password }) =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=password&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  refreshToken = () =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=refresh_token&refresh_token=${jwt.refreshToken}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );


  //Users
  getCurrentUser = () => api.get(API_URLS.USERS.CURRENT);
  updateUserProfile = (data) => api.put(API_URLS.USERS.ROOT, data);
  requestResetPassword = (data) => api.post(API_URLS.USERS.REQUEST_RESET_PASSWORD, data);
  confirmResetPassword = (data) => api.post(API_URLS.USERS.CONFIRM_RESET_PASSWORD, data);
  uploadKyc = (data) => api.post(API_URLS.USERS.UPLOAD_KYC, data, {
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  //Wallets
  getUserWallet = () => api.get(API_URLS.WALLETS.GET_USER_WALLET);

  //Stat
  getProfiting = () => api.get(API_URLS.STAT.PROFITING);

  //Rates
  getRatesHistoryByWeek = (params) => api.get(API_URLS.CURRENCIES.RATES_HISTORY_BY_WEEK, {params});

}

export const client = new Client();
