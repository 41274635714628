import axios from 'axios';
import { EventSourcePolyfill } from 'event-source-polyfill';
import {jwt} from "./jwt";

const baseUrl = process.env.REACT_APP_API_URL || '/api/v1';

export class ApiService {
  _httpClient;
  _baseURL;

  constructor(baseURL) {
    this._baseURL = baseURL;
    this._httpClient = axios.create({baseURL});
  }

  get httpClient() {
    return this._httpClient;
  }

  post = async (url, data = {}, config) => {
    try {
      const response = await this._httpClient.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          ...(config && config.headers ? config.headers : {}),
        },
        responseType: 'json',
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response !== undefined ? error.response.data : error);
    }
  };

  put = async (url, data = {}, config) => {
    try {
      const response = await this._httpClient.put(url, data, this.buildConfig(config));
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  };

  patch = async (url, data = {}, config) => {
    try {
      const response = await this._httpClient.patch(url, data, this.buildConfig(config));
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  };

  delete = async (url, config) => {
    try {
      const response = await this._httpClient.delete(url, this.buildConfig(config));
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  };

  get = async (url, config) => {
    try {
      const response = await this._httpClient.get(url, this.buildConfig(config));
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  };

  setStream = (url, params) => {
    const baseURL = 'https://bitethebrit.com/api/v1/'
    const queryString = params
      ? `?${Object.entries(params)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')}`
      : '';
    return new EventSourcePolyfill(`${baseURL}${url}${queryString}`, {
      // headers: {
      //   Authorization: `Bearer ${jwt.accessToken}`,
      // },
    });
  };


  buildConfig = (config) => {
    return config ? {...config, data: config.data ? config.data : null} : {data: null};
  };
}

export const api = new ApiService(baseUrl);
