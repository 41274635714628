import React from 'react';

export const getNextOptionIndex = (currentIndex, array, toNext) => {
  let newIndex = 0;

  if (array.length === 0) {
    return newIndex;
  }

  if (toNext) {
    const isLast = currentIndex >= array.length - 1;
    newIndex = isLast ? 0 : currentIndex + 1;
  } else {
    const isFirst = currentIndex <= 0;
    newIndex = isFirst ? array.length - 1 : currentIndex - 1;
  }

  return newIndex;
};

export const IfNilSwapToEmptyString = (value) => (isNil(value) ? '' : value);

export const sortObjectsArray = (
  byField,
  array,
  isDesc,
) => {
  const makeSortFunc = (item1, item2) => {
    const value1 = IfNilSwapToEmptyString(item1[byField]);
    const value2 = IfNilSwapToEmptyString(item2[byField]);
    if (value1 < value2) {
      return isDesc ? -1 : 1;
    }
    if (value1 > value2) {
      return isDesc ? 1 : -1;
    }
    return 0;
  };

  return [...array].sort(makeSortFunc);
};

const isUndefined = (value) => value === undefined;
const isNull = (value) => value === null;
export const isNil = (value) => isNull(value) || isUndefined(value);


export const convertToStringTime = (milliseconds) => {
  const minutes = Math.floor(milliseconds / 1000 / 60);
  const seconds = (milliseconds / 1000) % 60;
  const stringMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const stringSeconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${stringMinutes} min ${stringSeconds} sec`;
};


export const searchParamsToObject = (searchParams) => {
  const params = {}
  for (const [key, value] of searchParams.entries()) {
    params[key] = value
  }
  return params
}

export const toEur = (amount, prefix = true) => {
  return prefix ? amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'code'
  }) : amount.toLocaleString('en-US', {})
}

export const getFormattedDate = (date) => {
  let dd = date && date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return yyyy + "-" + mm + "-" + dd;
}
